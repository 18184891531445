import { useEffect, useState } from "react";
import cookieCutter from "cookie-cutter";

export const SetPassword = () => {
  const [password, setLoginPassword] = useState(false);
  const [componentMounted, setComponentMounted] = useState(false);

  const setMode = (mode) => {
    cookieCutter.set("pd-cookie--password", mode);
    setLoginPassword(mode);
  };

  const checkPassword = () => {
    setMode(true);
  };

  useEffect(() => {
    let cookiePassword = cookieCutter.get("pd-cookie--password");

    if (cookiePassword === undefined) {
      cookieCutter.set("pd-cookie--password", "", { expires: new Date(0) });
    } else {
      setMode(true);
    }
    setComponentMounted(true);
  }, []);

  return [password, checkPassword, componentMounted];
};
