const DeclarationBoxItemMinutes = (props) => {
  return (
    <div className="declaration-box__col">
      <div className="declaration-box declaration-box__5-minutes">
        <h3 className="declaration-box__5-minutes__title">
          Uzupełnij deklarację
        </h3>
        <p className="declaration-box__5-minutes__subtitle">w 5 minut</p>
      </div>
    </div>
  );
};

export default DeclarationBoxItemMinutes;
