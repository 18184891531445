import classNames from "classname";

const DeclarationBox = (props) => {
  // PROPS VARIABLE
  let propsMiasto = props.miasto;
  let propsPodatek = props.podatek;

  return (
    <>
      <div
        className={classNames("declaration-box__container", {
          "declaration-box__container--center": props.center,
          "declaration-box__container--5-minutes": props.minutes5,
        })}
      >
        {props.children}
      </div>
    </>
  );
};

export default DeclarationBox;
