import TranslatedMessage from "./TranslatedMessage";
import Image from "next/image";
import classNames from "classname";

import BackgroundSectionData from "../variables/variables.json";

const BackgroundSection = (props) => {
  // PROPS VARIABLE
  let propsMiasto = props.miasto;
  let propsSection = props.section;
  const formattedMessage =
    "miasta." + propsMiasto + ".sections." + propsSection;

  return (
    <>
      <section
        className={classNames("pd-section pd-learn-about", {
          "bgColor--shadow": props.bgColor === "shadow",
          "bgColor--lightblue": props.bgColor === "lightblue",
        })}
      >
        <div
          className={classNames("pd-section__inner-wrap", {
            center: props.center,
          })}
        >
          {props.attachment && (
            <Image unoptimized 
              className="pd-learn-about__background"
              alt=""
              src={
                BackgroundSectionData.miasta[propsMiasto].sections[propsSection]
                  .url.images
              }
              fill
              sizes="100vw"
              style={{
                objectFit: "cover",
              }}
            />
          )}
          {props.fillLeftTop && (
            <Image unoptimized 
              alt=""
              src={
                BackgroundSectionData.miasta[propsMiasto].sections[propsSection]
                  .url.images
              }
              fill
              sizes="100vw"
              style={{
                objectFit: "contain",
                objectPosition: "left top",
              }}
            />
          )}
          {props.fillRightTop && (
            <Image unoptimized 
              alt=""
              src={
                BackgroundSectionData.miasta[propsMiasto].sections[propsSection]
                  .url.images
              }
              fill
              sizes="100vw"
              style={{
                objectFit: "contain",
                objectPosition: "right top",
              }}
            />
          )}
          {props.fillLeftBottom && (
            <Image unoptimized 
              alt=""
              src={
                BackgroundSectionData.miasta[propsMiasto].sections[propsSection]
                  .url.images
              }
              fill
              sizes="100vw"
              style={{
                objectFit: "contain",
                objectPosition: "left bottom",
              }}
            />
          )}
          {props.fillRightBottom && (
            <Image unoptimized 
              alt=""
              src={
                BackgroundSectionData.miasta[propsMiasto].sections[propsSection]
                  .url.images
              }
              fill
              sizes="100vw"
              style={{
                objectFit: "contain",
                objectPosition: "right bottom",
              }}
            />
          )}
          {props.fillCenter && (
            <Image unoptimized 
              alt=""
              src={
                BackgroundSectionData.miasta[propsMiasto].sections[propsSection]
                  .url.images
              }
              fill
              sizes="100vw"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          )}
          <div
            className={classNames("pd-learn-about__container", {
              "pd-learn-about__text--left": props.textAlign === "left",
              "pd-learn-about__text--center": props.textAlign === "center",
              "pd-learn-about__text--right": props.textAlign === "right",
              transparent: props.transparent,
            })}
          >
            {props.customContent ? (
              props.children
            ) : (
              <>
                {props.title && (
                  <div
                    className={classNames("pd-learn-about__title", {
                      white: props.white,
                    })}
                  >
                    <TranslatedMessage id={formattedMessage + ".title"} />
                  </div>
                )}
                {props.subtitle && (
                  <p className="pd-learn-about__subtitle">
                    <TranslatedMessage id={formattedMessage + ".subtitle"} />
                  </p>
                )}
                {props.button == "primary" && (
                  <a
                    href={
                      BackgroundSectionData.miasta[propsMiasto].sections[
                        propsSection
                      ].url.button
                    }
                    className="pd-btn pd-btn--primary pd-btn--full"
                  >
                    <TranslatedMessage id={formattedMessage + ".url"} />
                  </a>
                )}
                {props.button == "outline" && (
                  <a
                    href={
                      BackgroundSectionData.miasta[propsMiasto].sections[
                        propsSection
                      ].url.button
                    }
                    className="pd-btn pd-btn--outline"
                  >
                    <TranslatedMessage id={formattedMessage + ".url"} />
                  </a>
                )}
                {props.button == "outlineWhite" && (
                  <a
                    href={
                      BackgroundSectionData.miasta[propsMiasto].sections[
                        propsSection
                      ].url.button
                    }
                    className="pd-btn pd-btn--outline white"
                  >
                    <TranslatedMessage id={formattedMessage + ".url"} />
                  </a>
                )}
                {props.button == "text" && (
                  <a
                    href={
                      BackgroundSectionData.miasta[propsMiasto].sections[
                        propsSection
                      ].url.button
                    }
                    className="pd-learn-about__link"
                  >
                    <TranslatedMessage id={formattedMessage + ".url"} />
                    <span className="chevron right"></span>
                  </a>
                )}
                {props.button == "dialog" && (
                  <button
                    data-a11y-dialog-show={props.dialogShow}
                    className="pd-learn-about__link pd-btn pd-btn--primary pd-btn--full"
                  >
                    <TranslatedMessage id={formattedMessage + ".url"} />
                  </button>
                )}
              </>
            )}
          </div>

          {props.dek && (
            <div className="pd-learn-about__deck">
              <div className="pd-learn-about__deck__image">
                <Image unoptimized 
                  src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/dek-siedzi.svg"
                  alt=""
                  width={252}
                  height={354}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default BackgroundSection;
